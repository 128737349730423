
  import { Component, Vue } from 'vue-property-decorator';
  import { Rubric } from '../interfaces/Rubric.interface';
  import RubricDatesLine from './RubricDatesLine.component.vue';
  import RubricImageSelector from './RubricImageSelector.vue';

  // constantes utilisées pour définir le comportement de la boite de dialogue
  const editType = "edit";
  const createType = 'create';

  /**
   * @desc composant de création / modification d'une rubrique
   */
  @Component({
    components: {
      'rubric-image-selector' : RubricImageSelector,
      'rubric-dates-line' : RubricDatesLine
    },
  })
  export default class EditRubricDialog extends Vue {
    boxWidth = "60rem";

    visible = false;
    rubric: Rubric = new Rubric();
    outputRubric : Rubric = new Rubric();
    type = 'create'; // default value -> create

    /**
     * @desc montre le composant
     * @param rubric : object contenant les données à modifier
     * @param type le comportement du composant, 'edit' ou 'create'
     */
    public show(rubric : Rubric, type : string) : void {
      this.rubric = rubric;
      this.outputRubric = rubric.clone();
      this.type = type;
      this.visible= true;
      this.$vuetify.theme.dark = false;

      setTimeout(() => {
        (this.$refs.rubricImgSelector as RubricImageSelector).loadImg(rubric.imgId);

        const dates =
          {
            startDate: this.rubric.startDate, 
            endDate: this.rubric.endDate, 
            archivedDate: this.rubric.archivedDate
          };
        (this.$refs.rubricDatesLine as RubricDatesLine).init(dates, type === createType);
      }, 200);
    }

    /**
     * @desc cache la boite de dialogue
     */
    public hide() : void {
      this.visible = false;
    }

    /**
     * @desc règles de la zone de texte du nom de la rubrique, vérifie s'il n'est pas vide
     */
    private rubricNameRules(input : string) {
      if(!input || !input.length) return "Le projet doit avoir un nom";
      return true;
    }

    /**
     * @desc valide les données renseignées par l'utilisateur et les renvoie en emettant un événement
     */
    public validate() : void{
      if(!this.checkInputData()) return;

      setTimeout(() => {
        if(!this.visible) return;
        this.visible = false;
        let editedRubric = this.getUpdatedData();
        if(this.type === editType) {
          const rubricEditionDto = this.outputRubric.createRubricEditionDto(this.rubric);
          if(Object.keys(rubricEditionDto).length <= 1){
            this.cancel();
          }
          else this.$emit('rubricValidated', rubricEditionDto)
        }
        else{
          this.$emit('rubricCreated', editedRubric)
        }
      }, 200);
    }

    /**
     * @desc vérifie les données renseignées par l'utilisateur
     * @returns false en cas de problème
     */
    private checkInputData() : boolean {
      if(!this.outputRubric.name) return false;
      if((this.$refs.rubricDatesLine as RubricDatesLine).hasError()) return false;

      return true;
    }

    /**
     * @desc renvoie un objet contenant les données renseignées par l'utilisateur
     */
    private getUpdatedData() : Rubric {
      const dates = (this.$refs.rubricDatesLine as RubricDatesLine).getDates();
      this.outputRubric.startDate = dates.startDate;
      this.outputRubric.endDate = dates.endDate;
      this.outputRubric.archivedDate = dates.archivedDate;

      const rubricImgSelector = this.$refs.rubricImgSelector as RubricImageSelector;
      if(rubricImgSelector.hasChanged()){
        this.outputRubric.imgUrl = rubricImgSelector.getDataUrl();
        this.outputRubric.imgChanged = true;
      }

      return this.outputRubric;
    }

    /**
     * @desc annule les modifications apportées. cache simplement la boite de dialogue
     */
    public cancel() : void {
      setTimeout(() => {
        this.visible = false;
      }, 200);
    }
  }

