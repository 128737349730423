
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import { AuthenticationService } from '../../services/authentication.service';
  import {Rubric} from '../../interfaces/Rubric.interface';
  import {ImageTransferService} from '../../services/image-transfer.service';
  import EditRubricDialog from '../../components/EditRubricDialog.component.vue';
  import { RubricEditionDto } from '@/interfaces/RubricEdition.dto';
  import DialogBoxValidation from '@/components/DialogBoxValidation.vue';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';


  const editRubricHeader = 'Éditer le projet';
  const editRubricType = 'edit';
  const createRubricHeader = 'Créer un projet';
  const createRubricType = 'create';

  /**
   * @desc page de gestion des projects d'école
   */
  @Component({
    components: {
      'edit-rubric-dialog' : EditRubricDialog,
      'deletion-dialog-box' : DialogBoxValidation
    },
  })
  export default class SchoolProjectsAdmin extends Vue {

    imageTransferService : ImageTransferService = new ImageTransferService();

    private tableHeaders = [
      { text: '#', sortable: false },
      { text: 'Titre', value: 'name', sortable: true },
      { text: 'Date de début', value: 'startDate', sortable: true },
      { text: 'Date de fin', value: 'endDate', sortable: true},
      { text: 'Date d\'archivation', value: 'archivedDate', sortable: true },
      { text: 'Actions', sortable: false },
      { text: '', sortable: false },
    ];

    private tableItems: Rubric[] = [];

    private allRubrics : Rubric[] = [];
    selectedRubric : Rubric = new Rubric();
    rubricEdition = false;

    editRubricHeader = "Éditer la rubrique";
    editRubricType = 'edit';

    private loading= false;
    private tableSortBy = 'startDate';
    private tableDesc = true;

    private dataToggle = 0;
    deletionEventName = 'rubricDeleted';

    /**
     * @desc initialisation et écoute des événements émis
     */
    mounted() :void {
      if(AuthenticationService.getUserStatus() !== Consts.user_status_teacher
        && AuthenticationService.getUserStatus() !== Consts.user_status_admin){
        this.$router.push('/');
        return;
      }
      this.loadSchoolProjects();
    }

    /**
     * @desc appel la mise à jour de l'affichage des rubriques
     */
    dataToggleChanged() : void {
      this.updateShowedRubrics();
    }
    
    /**
     * @desc met à jour l'affichage des rubriques / projets d'école
     */
    updateShowedRubrics() : void {
      const now = new Date();

      if(this.dataToggle === 1) { // archivés
        this.tableItems = this.allRubrics.filter(x => x.archivedDate < now);
      }
      else {
        this.tableItems = this.allRubrics.filter(x => x.archivedDate >= now);
      }
    }

    /**
     * @desc renvoie la Date au format string jj/mm/aaaa
     */
    toInputDate(date: Date) : string {
      let month = (date.getMonth() +1).toString();
      if(month.length === 1) month = "0" + month;
      let day = date.getDate().toString();
      if(day.length === 1) day = "0" + day;

      return day + '/' + month + '/' +  date.getFullYear().toString();
    }

    /**
     * @desc charge les projets d'école contenus en base de donnée
     */
    loadSchoolProjects() : void { 
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getAllProjects, headers)
        .then((response) => {
          this.allRubrics = response.data;
          // conversion en instance de Rubric
          for(let i=0; i < this.allRubrics.length; ++i) {
            this.allRubrics[i] = new Rubric(this.allRubrics[i]);
          }
          this.updateShowedRubrics();
        })
        .catch(error => console.log(error))
    }

    /**
     * @desc ouvre la boite de dialogue de création d'un projet d'école
     */
    addRubric() : void {
      this.rubricEdition = true;
      this.editRubricHeader = createRubricHeader;
      (this.$refs.editRubricDialog as EditRubricDialog).show(new Rubric(), createRubricType);
    }


    serverDialog = false;
    /**
     * @desc requète au serveur pour créer un projet d'école
     */
    async serverCreateProject(rubric : Rubric) : Promise<void> {
      await new Promise<any>((resolve) => {
        setTimeout(() => {
          resolve(0);
        }, 100);
      });
      if(this.serverDialog) return;
      this.serverDialog = true;

      rubric = await this.saveRubricImg(rubric) as Rubric;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.createProject, rubric, headers)
        .then((response) => {
          (this.$refs.editRubricDialog as EditRubricDialog).hide();
          const createdRubric = new Rubric(response.data.rubric);
          this.allRubrics.splice(0, 0, createdRubric);
          this.serverDialog = false;
          this.updateShowedRubrics();
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc met en forme et sauvegarde une image sur le serveur
     * @returns renvoie la rubrique modifiée avec le nouvel id d'image
     */
    async saveRubricImg(rubric : Rubric | RubricEditionDto) : Promise<Rubric | RubricEditionDto> {
      if(rubric.imgChanged) {
        if(rubric.imgUrl) {
          const imgId = await this.serverSaveImg(rubric.imgUrl);
          if(imgId) {
            rubric.imgId = imgId;
          }
        }
        else {
          rubric.imgId = -1;
        }
      }
      rubric.imgUrl = "";
      return rubric;
    }

    /**
     * @desc demande la sauvegarde d'une image côté serveur
     * @returns l'id de l'image sauvegardée
     */
    async serverSaveImg(imgUrl : string) : Promise<number> {
      const result = await this.imageTransferService.transferImg(imgUrl, UrlConsts.saveImg);
      if(result.createdImgId) return result.createdImgId;
      return 0;
    }

    /**
     * @desc ouvre la boite de dialogue d'édition d'un projet d'école
     */
    editRubric(rubric : Rubric) : void {
      if(!rubric) return;
      this.selectedRubric = rubric;
      this.rubricEdition = true;
      this.editRubricHeader = editRubricHeader;
      (this.$refs.editRubricDialog as EditRubricDialog).show(rubric, editRubricType);
    }

    /**
     * @desc requète serveur demandant la modifiaction d'une rubrique
     */
    async serverEditProject(rubric : RubricEditionDto) : Promise<void> {
      if(this.serverDialog) return;

      this.serverDialog = true;
      rubric = await this.saveRubricImg(rubric);

      const headers = AuthenticationService.getRequestHeader();
       axios
        .post(UrlConsts.editProject, rubric, headers)
        .then((response) => {
          this.serverDialog = false;
          const createdRubric = new Rubric(response.data.rubric);
          this.updateRubric(createdRubric);
        })
        .catch(error => this.serverError(error))
    }

    /**
     * @desc met à jour les données d'une rubrique et son affichage
     */
    updateRubric(data : Rubric) : void {
      const rubricIndex = this.allRubrics.findIndex(x => x.id === data.id);
        if(rubricIndex > -1) {
          this.allRubrics[rubricIndex] = data;
          this.updateShowedRubrics();
        }
    }
    
    /**
     * @desc ouvre la boite de dialogue de suppression de la rubrique selectionnée
     */
    deleteRubric(rubric : Rubric) : void {
      if(!rubric) return;
      this.selectedRubric = rubric;
      this.rubricEdition = true;
      (this.$refs.deletionDialogBox as DialogBoxValidation).show(this.selectedRubric);
    }

    /**
     * @desc demande la suppression d'une rubrique au serveur
     */
    serverDeleteRubric(rubric : Rubric) : void {
      if(this.serverDialog) return;

      this.serverDialog = true;

      const headers = AuthenticationService.getRequestHeader();
       axios
        .delete(UrlConsts.deleteProject + "/" + rubric.id, headers)
        .then(() => {
          this.serverDialog = false;
          const rubricIndex = this.allRubrics.findIndex(x => x.id === rubric.id);
          if(rubricIndex > -1) {
            this.allRubrics.splice(rubricIndex, 1);
          }
          this.updateShowedRubrics();
        })
        .catch(error => this.serverError(error))
    }

    serverError(error : string) : void{
      console.log(error);
      this.serverDialog = false;
    }
  }
